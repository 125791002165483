import React, { useState, useContext, useRef } from 'react'
import { Auth } from 'aws-amplify'
import { navigate } from 'gatsby'
import { GlobalStateContext } from '../context/GlobalContextProvider'
import * as styleLogin from './login.module.css'
import {
  Box,
  Container,
  Button,
  CircularProgress,
  Typography,
} from '@mui/material'

const LoginIndex = () => {
  const { gAuth } = useContext(GlobalStateContext)
  const [user, setUser] = useState({})
  const [btnDisabled, setBtnDisabled] = useState(true)
  const loginBtn = useRef(null)
  const [pagePwd, setPagePwd] = useState('')
  const [loginMail, setMail] = useState('')
  const [isVerifyCode, setVerifying] = useState(false) //判斷顯示輸入mail或顯示輸入密嗎
  const [isSigning, setIsSigning] = useState(false) //circle progress
  const [errHint, setErrHint] = useState(false)

  // 輸入完帳號時，按下enter等於點擊登入鍵
  const handleKeydown = (e) => {
    if (e.target.value !== '' && e.keyCode === 13) {
      loginBtn.current.click()
    }
  }

  return (
    <Container
      sx={{
        paddingTop: '24px',
      }}
    >
      <Box style={{ display: 'flex', justifyContent: 'center' }}>
        <img
          alt="logo"
          width={100}
          src="https://play-lh.googleusercontent.com/1aJhnph1EOYw8Uxv1OVctDs0e0vAAUbXlkBcyhAT3WGHLdcDraiRbxji4sdKn4PVUA=s360"
        />
      </Box>
      <input
        placeholder="your@email.com"
        value={loginMail}
        onKeyDown={(e) => {
          handleKeydown(e)
        }}
        onChange={(e) => {
          setMail(e.target.value)
          const mailReg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{1,}))$/
          const mailChk = mailReg.test(e.target.value)
          setBtnDisabled(!mailChk) // 驗證輸入，若不符合email格式則無法按下登入按鈕
        }}
        style={{ display: isVerifyCode ? 'none' : 'block' }}
        className={styleLogin.loginBox}
      />
      <Typography
        variant="h6"
        color="error"
        sx={{ display: errHint ? 'block' : 'none' }}
      >
        Verifying failed, please log in again.
      </Typography>
      <Button
        ref={loginBtn}
        sx={{
          color: '#fff',
          height: '45px',
          marginTop: '16px',
          display: isVerifyCode ? 'none' : 'block',
        }}
        fullWidth
        variant="contained"
        color="primary"
        disabled={btnDisabled}
        onClick={async () => {
          // [aws用戶登入-1(客製化驗證)]自動以輸入的email在aws用戶池註冊
          // 經過sign up challenge的lambda
          await Auth.signUp({
            username: loginMail,
            password: 'Aa123456',
          })
            .then((user) => {})
            .catch((err) => {
              console.log(err)
            })

          // [aws用戶登入-2(客製化驗證)]拿註冊好的aws用戶登入
          // 並經過sign up challenge =>define auth challenge => verify auth challange的lambda
          await Auth.signIn(loginMail)
            .then((user) => {
              // console.log('驗證', user)
              if (
                user.challengeName === 'CUSTOM_CHALLENGE' &&
                typeof window !== 'undefined'
              ) {
                // console.log('login', loginMail)
                gAuth.setLoginMail(loginMail)
                localStorage.setItem('currLogin', loginMail)
                localStorage.setItem('currAccountName', loginMail)
                setVerifying(true)
                setErrHint(false)
                setPagePwd('')
                setUser(user)
              }
            })
            .catch((err) => {
              console.log(err)
            })
        }}
      >
        ログイン
      </Button>
      <Box style={{ display: isVerifyCode ? 'block' : 'none' }}>
        <CircularProgress
          color="primary"
          sx={{
            display: isSigning ? 'block' : 'none',
            margin: '0 auto',
          }}
        />
        <Typography
          variant="h3"
          color="initial"
          sx={{
            display: isVerifyCode ? 'block' : 'none',
            textAlign: 'center',
            fontWeight: 'bold',
          }}
        >
          Enter the verification code sent to your email
        </Typography>
        <input
          variant="standard"
          placeholder="1234"
          type="number"
          value={pagePwd}
          disabled={!isVerifyCode}
          className={styleLogin.loginBox}
          onChange={(e) => {
            const currentPWD = e.target.value
            if (e.target.value.length <= 4) {
              setPagePwd(currentPWD)
            }
            if (currentPWD.length === 4) {
              setIsSigning(true)
              Auth.sendCustomChallengeAnswer(user, currentPWD) // 先經過create challenge的lambda
                .then((user) => {
                  // console.log('輸入完驗證碼', user)
                  setIsSigning(false)
                  if (user.signInUserSession) {
                    navigate('/')
                    // console.log('驗證通過', user)
                  } else {
                    setMail('')
                    setPagePwd('')
                    setVerifying(false)
                    setErrHint(true)
                  }
                })
            }
          }}
        />
      </Box>
    </Container>
  )
}

export default LoginIndex
